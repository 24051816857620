import { animate, state, style, transition, trigger } from '@angular/animations';

export enum ModalStateEnum {
  HIDDEN = 'hidden',
  INVISIBLE = 'invisible',
  VISIBLE = 'visible',
}

export enum ModalTypesEnum {
  CENTER,
  SIDE,
  BOTTOM,
}

export const ANIMATION_DURATION = 300;

const SLIDE_FROM_RIGHT_MODAL_ANIMATION = trigger('slideFromRight', [
  state(
    ModalStateEnum.INVISIBLE,
    style({
      transform: 'translateX(100%)',
    }),
  ),
  state(
    ModalStateEnum.VISIBLE,
    style({
      transform: 'translateX(0)',
    }),
  ),
  transition(`${ModalStateEnum.VISIBLE} <=> ${ModalStateEnum.INVISIBLE}`, [animate(ANIMATION_DURATION)]),
]);
const SLIDE_FROM_BOTTOM_MODAL_ANIMATION = trigger('slideFromBottom', [
  state(
    ModalStateEnum.INVISIBLE,
    style({
      transform: 'translateY(100%)',
    }),
  ),
  state(
    ModalStateEnum.VISIBLE,
    style({
      transform: 'translateY(0)',
    }),
  ),
  transition(`${ModalStateEnum.VISIBLE} <=> ${ModalStateEnum.INVISIBLE}`, [animate(ANIMATION_DURATION)]),
]);
const OPACITY_MODAL_ANIMATION = trigger('modalOpacity', [
  state(
    ModalStateEnum.INVISIBLE,
    style({
      opacity: 0,
    }),
  ),
  state(
    ModalStateEnum.VISIBLE,
    style({
      opacity: 1,
    }),
  ),
  transition(`${ModalStateEnum.INVISIBLE} <=> ${ModalStateEnum.VISIBLE}`, [animate(ANIMATION_DURATION)]),
]);
const VISIBILITY_MODAL_ANIMATION = trigger('modalVisibility', [
  state(
    ModalStateEnum.HIDDEN,
    style({
      opacity: 0,
      visibility: 'hidden',
    }),
  ),
  state(
    ModalStateEnum.INVISIBLE,
    style({
      opacity: 0,
      visibility: 'visible',
    }),
  ),
  state(
    ModalStateEnum.VISIBLE,
    style({
      opacity: 1,
      visibility: 'visible',
    }),
  ),
  transition(`${ModalStateEnum.HIDDEN} <=> ${ModalStateEnum.INVISIBLE}`, [animate('150ms ease-out')]),
  transition(`${ModalStateEnum.INVISIBLE} => ${ModalStateEnum.VISIBLE}`, [animate('150ms ease-out')]),
  transition(`${ModalStateEnum.VISIBLE} => ${ModalStateEnum.INVISIBLE}`, [animate(`150ms ${ANIMATION_DURATION}ms ease-out`)]),
]);

export const SIDE_MODAL_ANIMATION = [SLIDE_FROM_RIGHT_MODAL_ANIMATION, VISIBILITY_MODAL_ANIMATION];
export const CENTER_MODAL_ANIMATION = [OPACITY_MODAL_ANIMATION, VISIBILITY_MODAL_ANIMATION];
export const BOTTOM_MODAL_ANIMATION = [SLIDE_FROM_BOTTOM_MODAL_ANIMATION, VISIBILITY_MODAL_ANIMATION];
