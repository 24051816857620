import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ModalTypesEnum } from 'app/shared/components/modal/config/modal.config';

@Injectable({
  providedIn: 'root',
})
export class ModalHandlerService {
  private centerModalTemplate = new BehaviorSubject<TemplateRef<any> | null | undefined>(undefined);
  private sideModalTemplate = new BehaviorSubject<TemplateRef<any> | null | undefined>(undefined);
  private bottomModalTemplate = new BehaviorSubject<TemplateRef<any> | null | undefined>(undefined);

  constructor() {
    this.initializeModals();
  }

  public renderModal(template: TemplateRef<any>, type: ModalTypesEnum): void {
    switch (type) {
      case ModalTypesEnum.CENTER:
        this.centerModalTemplate.next(template);
        break;

      case ModalTypesEnum.SIDE:
        this.sideModalTemplate.next(template);
        break;

      case ModalTypesEnum.BOTTOM:
        this.bottomModalTemplate.next(template);
        break;
    }
  }

  public destroyModal(type: ModalTypesEnum): void {
    this.renderModal(null, type);
  }

  public getModalTemplate(type: ModalTypesEnum): Observable<TemplateRef<any>> {
    switch (type) {
      case ModalTypesEnum.CENTER:
        return this.centerModalTemplate.asObservable();

      case ModalTypesEnum.SIDE:
        return this.sideModalTemplate.asObservable();

      case ModalTypesEnum.BOTTOM:
        return this.bottomModalTemplate.asObservable();
    }
  }

  private initializeModals(): void {
    this.centerModalTemplate.asObservable().subscribe(template => {
      this.manageBodyScroll(template);
    });
    this.sideModalTemplate.subscribe(template => {
      this.manageBodyScroll(template);
    });
    this.bottomModalTemplate.subscribe(template => {
      this.manageBodyScroll(template);
    });
  }

  private manageBodyScroll(template: TemplateRef<any>): void {
    template ? document.body.classList.add('fixed') : document.body.classList.remove('fixed');
  }
}
